import Layout from 'EasySoftwareGatsby/components/core/Layout'
import { graphql } from 'gatsby'
import React, { FunctionComponent } from 'react'
import { localizedPathType } from 'Root/Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
// import EasySoftwareStartupJobs from 'Shared/components/EasySoftwareStartupJobs/EasySoftwareStartupJobs'
import PuxStructuredData from 'Shared/components/PuxStructuredData/PuxStructuredData'
import { WidgetPuxSectionType } from 'Shared/components/widgets/WidgetPuxSection'
import { StartupJobsDataType } from 'Shared/utils/getJobsData'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'

export interface HomePageType {
  localization: {
    localizations: localizedPathType[]
  }
}

interface PuxHomePageType {
  data: {
    orchard: {
      puxHomePage: [HomePageType]
    }
  }
  pageContext: {
    widgets: {
      widgetZone: [WidgetPuxSectionType]
    }
    metaTags: PuxMetaTagsType
    jobsData: StartupJobsDataType
  }
}

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      puxHomePage(first: 1, where: { path: $pagePath }) {
        localization {
          localizations {
            ... on Orchard_PuxHomePage {
              path
              published
              localization {
                culture
              }
            }
          }
        }
      }
    }
  }
`

const PuxHomePage: FunctionComponent<PuxHomePageType> = ({
  data,
  pageContext,
}) => {
  const locale = process.env.LOCALE
  const pageData = data.orchard.puxHomePage && data.orchard.puxHomePage[0]

  return (
    <Layout localizedPath={pageData.localization?.localizations}>
      <PuxStructuredData
        structuredData={{
          '@context': `https://schema.org`,
          '@type': `WebApplication`,
          name: `Easy Software`,
          sameAs: [
            `https://www.facebook.com/easysoftwarecom/`,
            `https://www.linkedin.com/company/easy-software-ltd./`,
          ],
          image: `softwarelogo.png`,
        }}
      />
      <PuxMetaTags {...pageContext.metaTags} />
      <WidgetBuilder widgetBuilderData={pageContext.widgets.widgetZone} />
      {/* 
      <div
        className='section-grey'
        id={`${locale === `cs` ? `kariera` : `career`}`}
      >
        <div className='pux-container'>
          <EasySoftwareStartupJobs jobs={pageContext.jobsData} />
        </div>
      </div>
      */}
      <div
        className='section'
        id={`${locale === `cs` ? `kontakt` : `contact`}`}
        dangerouslySetInnerHTML={{
          __html: pageContext.contact?.dangerousEmbeddedCodeSourceCode?.html,
        }}
      ></div>
    </Layout>
  )
}

export default PuxHomePage
